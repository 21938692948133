import { BasicBreadcrumbs, useIntl } from "@datwyler/mfe-shared-components";

const BreadcrumbsDeviceDetails = (props) => {
  const intl = useIntl();
  const { device } = props;

  return (
    <BasicBreadcrumbs
      items={[
        {
          title: intl.formatMessage({ id: "menu_home" }),
          href: "/home",
        },
        {
          title: intl.formatMessage({ id: "menu_device" }),
          href: "/device-monitoring",
        },
        {
          title: device?.name,
        },
      ]}
    />
  );
};

export default BreadcrumbsDeviceDetails;
