import React, { useState } from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {
  AxiosApiProvider,
  GraphQLApiProvider,
  NotificationProvider,
  DarkModeProvider,
  useLocale,
  TranslationProvider,
} from "@datwyler/mfe-shared-components";

export default function Root(props: any) {
  const { getDefaultLocale, useLocaleListener } = useLocale();
  const [locale, setLocale] = useState(getDefaultLocale());
  useLocaleListener(setLocale);

  return (
    <React.StrictMode>
      <TranslationProvider locale={locale}>
        <NotificationProvider>
          <AxiosApiProvider uri={props.REST_URL}>
            <GraphQLApiProvider uri={props.GRAPHQL_URL}>
              <BrowserRouter>
                <DarkModeProvider>
                  <App />
                </DarkModeProvider>
              </BrowserRouter>
            </GraphQLApiProvider>
          </AxiosApiProvider>
        </NotificationProvider>
      </TranslationProvider>
    </React.StrictMode>
  );
}
