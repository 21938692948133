import { useContext, useEffect, useState } from "react";
import { Col, Flex, Menu, Row, Tag, Typography, Form } from "antd";
import BreadcrumbsDeviceDetails from "../component/Common/Breadcrumbs/BreadcrumbsDeviceDetails";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useLoadingGif,
  useScreenOptions,
  DarkModeContext,
  useDevice,
  TenantIdContext,
  useIntl,
  ACTIVE,
  BasicButton,
  NotificationContext,
  useDeviceActions,
  SingleSelect,
  styled,
} from "@datwyler/mfe-shared-components";
import { ON } from "../utils/constants";
import { textStyle } from "../utils/styles";
import DeviceDetailsTable from "../component/DeviceDetails/DeviceDetailsTable";
import DeviceDetailsMoreButton from "../component/DeviceDetails/DeviceDetailsMoreButton";
import { getTelemetryData } from "../utils";
import SetParametersTable from "../component/DeviceDetails/SetParametersTable";

const { Text, Title } = Typography;

const getParameters = (device) => {
  return device?.telemetry?.filter(
    (data) => (data?.writable && data?.type) || false
  );
};

const defaultTab = "device_details";

const deviceTitleStyle = {
  fontSize: "14px",
  fontWeight: 400,
};

const DeviceDetails = () => {
  const {
    isFetchLoading: isFetchDeviceLoading,
    fetchDeviceData,
    fetchDevices,
  } = useDevice();

  const { tenantId }: any = useContext(TenantIdContext);
  const { isMobileScreen } = useScreenOptions();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const { isDarkMode }: any = useContext(DarkModeContext);
  const [selectedDevice, setSelectedDevice] = useState(
    location?.state?.device || null
  );
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [parameters, setParameters] = useState(getParameters(selectedDevice));
  const [changedParameters, setChangedParameters] = useState({});
  const { openNotification }: any = useContext(NotificationContext);

  const StyledMenuTabs = styled(Menu)`
    .ant-menu-item-selected {
      color: ${isDarkMode ? "#F1F7F8" : "#0C8595"} !important;
    }
  `;

  const StyledMenu = styled(Menu)`
    .ant-menu-item-active {
      color: #0c8595 !important;
    }

    .ant-menu-item-selected {
      color: #f1f7f8;
      background: #29303d;
    }
  `;

  const {
    setDeviceParams,
    isSetDeviceParamsLoading,
    setDeviceParamsResponseData,
  } = useDeviceActions();

  if (!selectedDevice) {
    navigate("/device-monitoring");
  }

  useEffect(() => {
    setIsLoading(isFetchDeviceLoading || isSetDeviceParamsLoading || false);
  }, [isFetchDeviceLoading, isSetDeviceParamsLoading]);

  useEffect(() => {
    if (tenantId) {
      refetchDevices();
    }
  }, [tenantId]);

  useEffect(() => {
    setSelectedTab(defaultTab);
    setParameters(getParameters(selectedDevice));
  }, [selectedDevice]);

  useEffect(() => {
    if (setDeviceParamsResponseData?.setDeviceParams?.status === "Executed") {
      openNotification({
        type: "success",
        description: intl.formatMessage({ id: "command_sent_with_delay" }),
        placement: "topRight",
      });
    }
  }, [setDeviceParamsResponseData]);

  const refetchDevices = () => {
    fetchDevices({
      variables: {
        tenantId: tenantId,
        filter: [`siteId:${selectedDevice?.site?.id}`, `status:${ACTIVE}`],
        page: { number: 0, size: 999999 }, //page in api start from 0
      },
    });
  };

  const sortByName = (array) => {
    if (!array || array?.length < 1) return [];
    return array.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  };

  const getMenuItems = (devices) => {
    if (!devices || devices.length < 1) return [];
    return devices?.map((device) => ({
      key: device.id,
      label: device.name,
      style: {
        padding: "12px 8px",
      },
      onClick: () => {
        setSelectedDevice(device);
      },
    }));
  };

  const tabs = [
    {
      label: intl.formatMessage({ id: "device_detail" }),
      key: "device_details",
    },
  ];

  if (parameters?.length > 0)
    tabs.push({
      label: intl.formatMessage({ id: "set_parameter" }),
      key: "set_parameters",
    });

  const onChangeParameters = (values) => {
    setChangedParameters(values);
  };

  const onSaveSetParameters: any = () => {
    const params = Object.keys(changedParameters).map(
      (key) => key + "=" + changedParameters[key]
    );

    setDeviceParams({
      variables: {
        input: {
          tenant: { id: tenantId },
          device: { id: selectedDevice.id },
          params: params,
        },
      },
    });
  };

  const initialValues = parameters?.reduce((total, p) => {
    let value = p.value;

    // check if boolean data type returns a float
    if (p.type === "BOOLEAN") {
      switch (p.value) {
        case "true":
        case "false":
          value = p.value === "true";
          break;
        case "0.00":
        case "0":
        case "1":
        case "1.00":
          value = parseFloat(p.value) === 1;
          break;
        default:
          value = false;
          openNotification({
            type: "error",
            description: intl.formatMessage({ id: "err_set_params" }),
            placement: "topRight",
          });
      }
    }

    return { ...total, [p.name]: value };
  }, {});

  const getDeviceOptions = (devices) => {
    if (!devices || devices.length < 1) return [];
    return devices?.map((device) => ({
      label: device.name,
      value: device.id,
    }));
  };

  const findDeviceById = (id) => {
    return fetchDeviceData?.devices?.devices?.find(
      (device) => device.id === id
    );
  };

  return (
    <Row style={{ marginBottom: "24px" }} gutter={[0, 24]}>
      <LoadingGif />
      {!isMobileScreen && (
        <Col span={24}>
          <BreadcrumbsDeviceDetails device={selectedDevice} />
        </Col>
      )}
      {isMobileScreen && (
        <Row gutter={[0, 12]} style={{ width: "100%" }}>
          <Col span={24}>
            <Text style={deviceTitleStyle}>
              {intl.formatMessage({ id: "device" })}
            </Text>
          </Col>
          <Col span={24}>
            <SingleSelect
              options={getDeviceOptions(
                sortByName(fetchDeviceData?.devices?.devices)
              )}
              style={{ width: "100%", height: "57.6px" }}
              popupMatchSelectWidth={"100%"}
              onChange={(id) => setSelectedDevice(findDeviceById(id))}
              placeholder={selectedDevice?.name}
            />
          </Col>
        </Row>
      )}
      <Col span={24}>
        <Flex gap={"24px"} style={{ width: "100%" }} align="start">
          {!isMobileScreen && (
            <Flex>
              <StyledMenu
                style={{
                  width: 188,
                  padding: 16,
                  border: isDarkMode
                    ? "1px solid #5A5C5F"
                    : "1px solid #E1E1E4",
                  borderRadius: "4px",
                }}
                defaultSelectedKeys={[selectedDevice?.id]}
                mode="inline"
                items={getMenuItems(
                  sortByName(fetchDeviceData?.devices?.devices)
                )}
              />
            </Flex>
          )}
          <Flex style={{ width: "100%" }}>
            <Row style={{ width: "100%" }}>
              <Col
                span={23}
                style={{ display: "inline-flex", alignItems: "center" }}
              >
                <Title
                  level={2}
                  style={{
                    marginTop: "0px",
                    marginRight: "16px",
                    display: "inline-block",
                  }}
                >
                  {selectedDevice?.name}
                </Title>
                {selectedDevice?.telemetry && (
                  <Tag
                    style={{ display: "inline-block" }}
                    color={
                      getTelemetryData(selectedDevice?.telemetry, "status") ===
                      ON
                        ? "green"
                        : "red"
                    }
                  >
                    {getTelemetryData(selectedDevice?.telemetry, "status") ===
                    ON
                      ? intl.formatMessage({ id: "online" })
                      : intl.formatMessage({ id: "offline" })}
                  </Tag>
                )}
              </Col>
              <Col span={1} style={{ textAlign: "right" }}>
                <DeviceDetailsMoreButton
                  selectedDevice={selectedDevice}
                  tenantId={tenantId}
                  setSelectedDevice={setSelectedDevice}
                  refetchDevices={refetchDevices}
                />
              </Col>
              <Col span={24}>
                <Text style={textStyle}>{selectedDevice?.site?.name}</Text>
              </Col>
              <Col span={24}>
                <StyledMenuTabs
                  style={{
                    background: "none",
                    border: "none",
                    marginTop: "40px",
                  }}
                  onClick={(e) => {
                    setSelectedTab(e.key);
                  }}
                  selectedKeys={[selectedTab]}
                  mode="horizontal"
                  items={tabs}
                />
              </Col>
              {selectedTab === "device_details" && (
                <Col span={24} style={{ marginTop: "24px" }}>
                  {selectedDevice?.telemetry?.length > 0 && (
                    <Row gutter={[24, 32]}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <DeviceDetailsTable
                          data={selectedDevice.telemetry?.slice(
                            0,
                            Math.round(selectedDevice.telemetry.length / 2)
                          )}
                        />
                      </Col>
                      {selectedDevice.telemetry?.length > 1 && (
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                          <DeviceDetailsTable
                            data={selectedDevice.telemetry?.slice(
                              Math.round(selectedDevice.telemetry.length / 2)
                            )}
                          />
                        </Col>
                      )}
                    </Row>
                  )}
                </Col>
              )}
              {selectedTab === "set_parameters" && (
                <Col span={24} style={{ marginTop: "24px" }}>
                  {parameters?.length > 0 && (
                    <Form
                      name="setParameters"
                      onFinish={onSaveSetParameters}
                      onValuesChange={onChangeParameters}
                      autoComplete="off"
                      initialValues={initialValues}
                    >
                      <Flex justify="flex-end" style={{ marginBottom: "16px" }}>
                        <BasicButton type="primary" htmlType="submit">
                          {intl.formatMessage({ id: "save" })}
                        </BasicButton>
                      </Flex>
                      <Row gutter={[24, 32]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                          <SetParametersTable
                            data={parameters.slice(
                              0,
                              Math.round(parameters.length / 2)
                            )}
                          />
                        </Col>
                        {parameters.length > 1 && (
                          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                            <SetParametersTable
                              data={parameters.slice(
                                Math.round(parameters.length / 2)
                              )}
                            />
                          </Col>
                        )}
                      </Row>
                    </Form>
                  )}
                </Col>
              )}
            </Row>
          </Flex>
        </Flex>
      </Col>
    </Row>
  );
};

export default DeviceDetails;
