import {
  TenantIdProvider,
  DarkModeContext,
  ContentWrapper,
} from "@datwyler/mfe-shared-components";
import Router from "./Router";
import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { ConfigProvider } from "antd";

const App = (props: any) => {
  const location = useLocation();
  const [tenantId, setTenantId] = useState(null);
  const { theme } = useContext<any>(DarkModeContext);

  useEffect(() => {
    if (location?.state?.tenantId) setTenantId(location.state.tenantId);
  }, [location]);

  return (
    <TenantIdProvider tenantId={tenantId}>
      <ConfigProvider theme={theme}>
        <ContentWrapper>
          <Router />
        </ContentWrapper>
      </ConfigProvider>
    </TenantIdProvider>
  );
};

export default App;
